import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Button = styled.button`
  color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(255, 255, 255, 1);
  border-radius: 3px;
  background: transparent;
  text-transform: uppercase;
  opacity: 0.45;
  padding: 0;
  margin: 0 auto;
  display: block;
  .container {
    display: grid;
    grid-template-columns: 150px 50px;
    align-items: center;
    justify-items: center;
    min-width: 200px;
    .txt {
      padding: 12px 25px;
    }
    .icon {
      position: relative;
      border-left: 1px solid #fff;
      padding: 20px 14px;
      width: 100%;
      height: 46px;
      span {
        display: block;
        position: absolute;
        height: 2px;
        width: 21px;
        background: #fff;
        border-radius: 3px;
        opacity: 1;
        left: 14px;
        top: 22px;
        transition: 0.25s ease-in-out;
      }
      span:nth-child(1) {
        transform: rotate(0deg);
      }
      span:nth-child(2) {
        transform: rotate(${props => (props.showMore ? '0deg' : '90deg')});
      }
    }
  }
  transition: all 0.2s ease-in-out;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`

const ButtonMore = ({ text, action, showMore }) => (
  <Button onClick={action} showMore={showMore}>
    <div className="container">
      <div className="txt">{text}</div>
      <div className="icon">
        <span />
        <span />
      </div>
    </div>
  </Button>
)

ButtonMore.propTypes = {
  text: PropTypes.string.isRequired,
  showMore: PropTypes.bool.isRequired,
}
ButtonMore.defaultProps = {
  text: 'Load More',
  showMore: false,
}

export default ButtonMore
