import React from 'react'
import styled from 'styled-components'
import avatar from '../../images/fb-avatar.jpg'
import iconCalendar from '../../images/icon-calendar.svg'
import iconBook from '../../images/icon-book.svg'
import IconArrow from '../../images/icon-readmore-arrow.svg'
import posed from 'react-pose'

const ArticleAnimation = posed.div({
  open: { y: 0, opacity: 1 },
  closed: { y: -50, opacity: 0 },
})

const ArticleItem = styled.a`
  background-color: #f2f2f2;
  color: rgba(0, 0, 0, 0.9);
  margin: 0 0 40px 0;
  display: grid;
  grid-template-columns: 465px 1fr;
  h2 {
    text-transform: uppercase;
    line-height: 35px;
    margin-bottom: 20px;
    span {
      background-image: linear-gradient(
        to right,
        ${props => props.color} 0%,
        ${props => props.color} 100%
      );
      background-size: 0px 3px;
      background-position: 0px 90%;
      transition: background-size 0.8s cubic-bezier(0.19, 1, 0.155, 1.01) 0s;
      background-repeat: no-repeat;
    }
  }
  p {
    margin: 0;
  }
  .img {
    margin: 15px;
    overflow: hidden;
    line-height: 0;
  }
  .img img {
    transition: all 0.2s ease-in-out;
  }
  .body {
    padding: 40px 40px 20px 20px;
  }
  &:hover {
    background: #fff;
    .img img {
      transform: scale(1.1, 1.1);
    }
    h2 span {
      background-size: 100% 3px;
    }
    .button {
      background-position: 130px center;
      border-color: ${props => props.color};
      color: ${props => props.color};
      #BtnMoreArrow {
        stroke: ${props => props.color};
        opacity: 1;
      }
      svg {
        left: 15px;
      }
    }
  }
  .body {
    .meta {
      display: grid;
      grid-template-columns: 40px auto 30px auto 30px 1fr;
      align-items: center;
      grid-gap: 15px;
      color: rgba(30, 27, 34, 0.8);
      margin-bottom: 20px;
    }
    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 100px;
    }
    .icon {
      padding-left: 10px;
    }
  }
  @media (max-width: 1024px) {
    grid-template-columns: 350px 1fr;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    .body {
      padding: 20px;
    }
    .img {
      margin-bottom: 10px;
      transition: all 300ms ease-in-out;
      img {
        width: 100%;
      }
    }
    &:hover {
      .img {
        margin: 3px 3px 4px 3px;
      }
    }
  }
  @media (max-width: 600px) {
    .body .meta {
      font-size: 14px;
      .avatar {
        height: 25px;
        width: 25px;
      }
      .icon {
        padding: 0;
      }
      grid-template-columns: 25px 1fr;
      grid-template-rows: 25px;
      grid-row-gap: 10px;
      .icon,
      .avatar {
        justify-self: center;
      }
    }
    .button {
      width: 100%;
      grid-template-columns: 1fr 50px;
      justify-items: center;
    }
  }
`
const Button = styled.div`
  border: 1px solid #382d4c;
  text-transform: uppercase;
  color: #382d4c;
  border-radius: 3px;
  display: grid;
  grid-template-columns: 100px 50px;
  width: 200px;
  margin: 20px 0 0 0;
  padding: 15px 25px;
  transition: all 0.2s ease-in-out;
  svg {
    position: relative;
    left: 5px;
    top: 1px;
    transition: all 0.2s ease-in-out;
  }
  #BtnMoreArrow {
    transition: all 0.2s ease-in-out;
    stroke: #382d4c;
    opacity: 0.4;
  }
`

const ArticleListItem = ({ data, isVisible }) => (
  <ArticleAnimation pose={isVisible ? 'open' : 'closed'}>
    <ArticleItem href={data.acf.link} target="_blank" color={data.acf.color}>
      <div className="img">
        <img src={data.featured_media.source_url} alt={data.title} />
      </div>
      <div className="body">
        <h2>
          <span>{data.title}</span>
        </h2>
        <div className="meta">
          <img src={avatar} alt="Francois Brill" className="avatar" />
          Francois Brill
          <img src={iconBook} alt="Time to Read" className="icon" />
          {data.acf.read_time}
          <img src={iconCalendar} alt="Date Published" className="icon" />
          {data.date}
        </div>
        <p
          dangerouslySetInnerHTML={{
            __html: data.acf.intro,
          }}
        />
        <Button icon={IconArrow} className="button">
          Read More
          <svg
            width="36px"
            height="19px"
            viewBox="0 0 36 19"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <title>icon-readmore-arrow</title>
            <g
              id="BtnMoreArrow"
              transform="translate(-779.000000, -1308.000000)"
              stroke="#3E2E5C"
              strokeWidth="1.5"
            >
              <g
                id="ArticleItem-Copy"
                transform="translate(100.000000, 962.000000)"
              >
                <g id="Btn-More" transform="translate(544.000000, 330.000000)">
                  <g id="Group" transform="translate(21.000000, 13.000000)">
                    <g
                      id="icon-readmore-arrow"
                      transform="translate(115.000000, 3.000000)"
                    >
                      <path d="M6.5,9.5 L33.3495708,9.5" id="lineLong" />
                      <path
                        d="M24.7146068,0.918189674 L33.5,9.5"
                        id="lineDiagonalTop"
                      />
                      <path
                        d="M24.8333333,9.74957911 L33.5,18.5"
                        id="lineDiagonalBottom"
                        transform="translate(29.000000, 14.000000) scale(1, -1) translate(-29.000000, -14.000000) "
                      />
                      <path
                        d="M2.09090909,9.5 L0.522727273,9.5"
                        id="lineShort"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </Button>
      </div>
    </ArticleItem>
  </ArticleAnimation>
)

export default ArticleListItem
