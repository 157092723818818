import React, { Component } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import TrackVisibility from 'react-on-screen'
import ArticleListItem from './articleListItem'
import ButtonMore from '../shared/btnMore'

const ArticleListSectionWrapper = styled.div`
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.45) 5%,
    rgb(20, 8, 30) 100%
  );
  margin-bottom: ${props => (props.compact ? '-30px' : '130px')};
`
const ArticleListSectionInner = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  top: -80px;
  @media (max-width: 1280px) {
    margin: 0 20px;
  }
  .article.hide {
    display: ${props => (props.showMore ? 'grid' : 'none')};
  }
`

class ArticlesPageContainer extends Component {
  state = {
    showMore: false,
  }

  showMoreHandler = () => {
    const { showMore } = this.state
    this.setState({
      showMore: !showMore,
    })
    // Force TrackVisibility to kick-in
    window.scrollBy(0, 1)
  }

  render() {
    const { compact } = this.props
    const { showMore } = this.state
    return (
      <StaticQuery
        query={ARTICLES_CONTENT}
        render={({ allWordpressWpArticle }) => (
          <ArticleListSectionWrapper compact={compact}>
            <ArticleListSectionInner compact={compact} showMore={showMore}>
              {allWordpressWpArticle.edges.map(({ node }, i) => (
                <TrackVisibility
                  partialVisibility
                  key={node.id}
                  className={`article ${i < 3 || !compact ? 'show' : 'hide'}`}
                >
                  <ArticleListItem data={node} />
                </TrackVisibility>
              ))}
              {compact && (
                <ButtonMore
                  text={showMore ? 'Show Less' : 'Load More'}
                  action={this.showMoreHandler}
                  showMore={showMore}
                />
              )}
            </ArticleListSectionInner>
          </ArticleListSectionWrapper>
        )}
      />
    )
  }
}

const ARTICLES_CONTENT = graphql`
  query ArticlesListDetails {
    allWordpressWpArticle(sort: { fields: date, order: DESC }) {
      edges {
        node {
          id
          title
          date(formatString: "DD MMMM YYYY")
          acf {
            link
            intro
            color
            read_time
          }
          featured_media {
            source_url
          }
        }
      }
    }
  }
`

export default ArticlesPageContainer
