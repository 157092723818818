import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Header from '../components/header/header'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PageDetailedHeader from '../components/shared/pageDetailedHeader'
import ArticlesPageContainer from '../components/articles/articlesPageContainer'

const ArticlesPage = () => {
  const data = useStaticQuery(graphql`
    {
      headerBG: file(relativePath: { eq: "page-header-articles.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Articles" keywords={[`design`, `developer`, `react`]} />
      <Header colour="#50E3C2" />
      <PageDetailedHeader
        bg={data.headerBG}
        intro={`Writing original articles based on my perspective and experiences over the years has provided me with the opportunity to learn and grow. My articles have been features in publications like <a href="https://uxdesign.cc/@fbrill" target="_blank">UX Collective</a> and <a href="https://medium.muz.li/@fbrill" target="_blank">Muzli by InVision</a>.`}
        hasOverlap
      />
      <ArticlesPageContainer />
    </Layout>
  )
}

export default ArticlesPage
