import React from 'react'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

const PageHeaderStyled = styled(BackgroundImage)`
  height: 430px;
  text-align: center;
  margin-top: -94px;
  padding-top: ${props => (props.hasOverlap ? '20px' : '100px')};
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  display: grid;
  align-items: center;
  font-family: ${props => props.theme.poppins};
  font-size: 20px;
  letter-spacing: 0.6px;
  p {
    max-width: 850px;
    margin: 0 auto;
    font-size: 18px;
    a {
      color: #fff !important;
      text-decoration: none;
      border-bottom: 1px solid rgba(80, 227, 194, 0.38);
      transition: all 200ms ease-in-out;
      &:hover {
        border-bottom: 1px solid rgba(80, 227, 194, 1);
      }
    }
  }
`

const PageDetailedHeader = ({ bg, intro, hasOverlap }) => (
  <PageHeaderStyled fluid={bg.childImageSharp.fluid} hasOverlap={hasOverlap}>
    <p dangerouslySetInnerHTML={{ __html: intro }} />
  </PageHeaderStyled>
)

export default PageDetailedHeader
